import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  chart: {
    height: '285px',
    width: '100%',
  },
}));

export default useStyles;
